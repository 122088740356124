import "regenerator-runtime/runtime.js";

import Vue from 'vue';
import { httpGet } from "./common/util";
import Torrent from './Torrent.vue';
import Icon from 'vue-awesome/components/Icon'

Vue.component('icon', Icon);

new Vue({
    el: '#torrent',
    data: {
        baseURI: document.head.getElementsByTagName('base')[0].href.replace(/\/$/,''),
        lang: {}
    },

    async beforeCreate() {
        // Fetch translations
        try {
          this.lang = await httpGet('lang.json');
        }
        catch (e) {
          alert(e);
        }
    },

    render: h => h(Torrent),
});
