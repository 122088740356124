<template lang="pug">
  .torrent-app#torrentApp
      h3 {{ $root.lang.torrent_info }}
      h1#logHeading(style='display: none') Log
      .speed
      .log

      p
        | #{ ' ' }
        input(type='file', name='upload', multiple)

      section
        form(hidden=true)
          input(name='torrentId', placeholder='magnet:', required)
          button(type='submit') Download
</template>

<script>
  export default {
    name: 'Torrent',
  }
</script>

</style>
